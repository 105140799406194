import { getCRDTItem, applyChangesToYType } from "../multi-user/redux";
import { CRDTState, YTransactionTypes } from "../../globals";
import { Y, ydoc } from "../multi-user";
import _ from "lodash";

export const deleteMedia = (options, imageToDelete) => {

    if(!options.target) {
        console.log('No target for deletion. Pass a CRDTItem')
        return;
    } else {
        options.target = options.target.CRDTItem || options.target
    }

    if(!options.field) {
        console.log('No field name given. Pass a string of the field to write the image to.')
        return;
    }

    let targetMediaArray = options.target.get(options.field);

    // if media is not a shared array yet
    if(targetMediaArray instanceof Y.Array === false){
        // create a shared array
        targetMediaArray = new Y.Array();
        // inject the old data in the new shared array
        targetMediaArray.insert(0, options.target.get(options.field) || []);
        // set the new shared media array on the page's crdt item
        options.target.set(options.field, targetMediaArray);
    }

    const hashToDelete = imageToDelete instanceof Y.Map ? imageToDelete.get('hash') : imageToDelete.hash;

    targetMediaArray.forEach((image, index) => {

        const imageIsYJSType = image instanceof Y.Map;

        if(
            (imageIsYJSType ? image.get('crdt_state') : image.crdt_state) === CRDTState.Deleted
            && (imageIsYJSType ? image.get('is_deleted') : image.is_deleted) === true
        ) {
            return;
        }

        const hash = imageIsYJSType ? image.get('hash') : image.hash;

        if(hash === hashToDelete) {

            ydoc.transact(() => {

                if(!imageIsYJSType) {
                    // convert to a shared type
                    image = applyChangesToYType(new Y.Map(), image);
                    // delete the plain object model
                    targetMediaArray.delete(index);
                    // replace it with the YJS type
                    targetMediaArray.insert(index, [image]);
                }

                // for the publish step
                image.set('crdt_state', CRDTState.Deleted);

                // for the api
                image.set('is_deleted', true);

            });

        }

    });

}
