import { getSupportedFileTypes } from "../../components/ui-kit/helpers";

export const convertFileToImage = file =>
    new Promise(function (resolve, reject) {
        switch ( true ) {
            case getSupportedFileTypes('image').includes(file.type): {

                var img = new Image();

                img.onload = function () {
                    // Resolve the promise
                    img.setAttribute('width', img.naturalWidth);
                    img.setAttribute('height', img.naturalHeight);
                    img.onload = null;

                    resolve(img);
                };

                img.onerror = function () {
                    // unable to load image, resolve empty
                    resolve(null)
                };

                img.src = URL.createObjectURL(file);

                break;
            }


            default:

                resolve(null);

        }

    });


export const convertFileToVideo = (file) =>

    new Promise(function (resolve, reject) {

        switch ( true ) {
            case getSupportedFileTypes('video').includes(file.type):

                var video = document.createElement('video');
                video.setAttribute('preload', 'auto');
                video.setAttribute('muted', '');
                video.setAttribute('playsinline', '');

                video.onloadedmetadata = ()=>{
                    video.onloadedmetadata = null;


                    // Resolve the promise
                    video.setAttribute('width', video.videoWidth);
                    video.setAttribute('height', video.videoHeight);
                    resolve(video);                    

                }
                video.onerror = function (event) {
                    resolve(null);
                };

                video.src = URL.createObjectURL(file);
                video.load();
                break;

            default: 
                resolve(null);
                break;

        }
    });



