import { convertFileToImage, convertFileToVideo } from "./convertFileToImage";
import { getS3PreSignedUploadUrl } from "./getS3PreSignedUploadUrl";
import { getS3MediaURL } from "./getS3MediaURL";
import { CRDTState, YTransactionTypes } from "../../globals";
import { getCRDTItem, applyChangesToYType } from "../multi-user/redux";
import { Y, wsProvider } from "../multi-user";
import _ from "lodash";
import { HashId } from "@cargo/hash-id";
import { store } from "../../index"
import { actions } from "../../actions";
import { API_ORIGIN, helpers } from "@cargo/common";
import axios from "axios";
import { getSupportedFileTypes } from "../../components/ui-kit/helpers";

// Keep track of the order in which files are being
// uploaded to a certain target so we can insert them
// in the order they were started
const sortedHashesByUploadTarget = new Map();

export const uploadFavicon = async (file) => {

    // generate a hash for every file in the array
    const state = store.getState();
    const siteId = state.site.id;
    const userId = state.auth?.data?.id ?? 0;
    HashId.disableCompression();
    await HashId.enablePrefix();

    const hashIdGenerator = HashId.generator(siteId, userId)
    const hash = hashIdGenerator.next().value;

    // add dashes and remove unsafe chars
    file.name = file.name
        .replace(/[#&?+,;"\'\\\^\ ]/g, "-")
        .replace(/([^a-zA-Z0-9-_\.]+)/g, "");

    const presignedURLResult = await getS3PreSignedUploadUrl(file, hash, {
        favicon: true
    });

    await axios.put(presignedURLResult.data.url, file, {
        headers: {
            "Content-Type": file.type,
        }
    })

    return presignedURLResult.data.media;

}

export const uploadSupportMedia = async (file) => {

    // generate a hash for every file in the array
    const state = store.getState();
    const siteId = state.site.id;
    const userId = state.auth?.data?.id ?? 0;
    const isImage = file.type.startsWith('image');
    const isVideo = file.type.startsWith('video');

    HashId.disableCompression();
    await HashId.enablePrefix();

    const hashIdGenerator = HashId.generator(siteId, userId)
    const hash = hashIdGenerator.next().value;

            // add dashes and remove unsafe chars
            const safeFileName = file.name
            .replace(/[#&?+,;"\'\\\^\ ]/g, "-")
            .replace(/([^a-zA-Z0-9-_\.]+)/g, "");

        // convert file to generic blob so we can change the name
        // and any backend process the file gets uploaded to will correctly read it
        const renamedFile = new Blob([file], { type: file.type });
        renamedFile.name = safeFileName;

        const s3SignRequestData = {};

        // only create one video element throughout this process
        // this will be used to create the image blob for the poster later
        let videoEl;

        if( isImage ){

            const imgEl = await convertFileToImage(renamedFile);

            if(imgEl) {

                if(file.onElementCreation) {
                    file.onElementCreation(imgEl);
                }

                // width and height only for valid image uploads
                s3SignRequestData.width = imgEl.naturalWidth;
                s3SignRequestData.height = imgEl.naturalHeight;
            }

        } else if( isVideo ){

            videoEl = await convertFileToVideo(renamedFile);


            if(videoEl) {

                if(file.onElementCreation) {
                    file.onElementCreation(videoEl);
                }

                // width and height only for valid image uploads
                s3SignRequestData.width = videoEl.videoWidth;
                s3SignRequestData.height = videoEl.videoHeight;
            } else {

                // the presigned URL Promise will return a failure message
                // if uploading an obviously incorrect file format like .avi
                // however, in more subtle cases- like a plain mpeg-4 file in Chrome or
                // h265 on a platform that doesn't support it, the video creation will 
                // fail. We show this error here:
                const showRemoteAlert = new CustomEvent('open-remote-alert', {
                    detail: {
                        message: 'This type of file is not supported',
                    }
                });

                document.dispatchEvent( showRemoteAlert );
                return
            }            

        }

        const presignedURLResult = await getS3PreSignedUploadUrl(renamedFile, hash, {
            data: s3SignRequestData,
            support: true
        });

        await axios.put(presignedURLResult.data.url, renamedFile, {
            headers: {
                "Content-Type": renamedFile.type,
            }
        })
        
        return presignedURLResult.data.media;
}

const uploadsInProgress = [];
let modelsBeingUploaded = [];

// Attempt to prevent window unloading while we're uploading
const unBeforeUnloadEventHandler = function (e) {
  e.preventDefault();
  e.returnValue = '';
}

// if we end up unloading the page, make sure we don't leave behind unfinished models
window.addEventListener("pagehide", (event) => {

    ydoc.transact(() => {

        modelsBeingUploaded.forEach(mediaModel => {
             // delete the media model
            if(
                mediaModel 
                && mediaModel.parent 
                && mediaModel.parent instanceof Y.Array
            ) {

                const arr = mediaModel.parent.toArray();

                for (let i = arr.length - 1; i >= 0; i--) {
                    if(arr[i] === mediaModel) {
                        mediaModel.parent.delete(i, 1);
                    }
                }

            }
        });

    }, YTransactionTypes.NotUndoable);

}, false);

const onUploadStart = uploadId => {

    const isFirstUpload = uploadsInProgress.length === 0;

    // remove from in progress
    uploadsInProgress.push(uploadId);

    // only run this if this is the first upload of this sequence
    if(uploadsInProgress.length === 1) {

        window.addEventListener('beforeunload', unBeforeUnloadEventHandler);

        // tell adminState we are uploading — this is to prevent navigation while uploading
        store.dispatch(actions.updateAdminState({
            uploadingMedia: true
        }));

        // let others know an upload is in progress
        wsProvider.setAwarenessField('uploadInProgress', true);

    }

}

const onUploadComplete = uploadId => {

    // remove from in progress
    _.pull(uploadsInProgress, uploadId);

    // only run this once the last upload has completed
    if(uploadsInProgress.length === 0) {

        sortedHashesByUploadTarget.clear();

        // failsave just in case we failed to remove all completed models.
        modelsBeingUploaded = [];

        // remove unbeforeunload
        window.removeEventListener('beforeunload', unBeforeUnloadEventHandler);

        // tell adminState we are no longer uploading
        store.dispatch(actions.updateAdminState({
            uploadingMedia: false
        }));

        // let others know no more uploads are in progress
        wsProvider.setAwarenessField('uploadInProgress', false);

    }

}

export const uploadURL = (options, url) => {

    return new Promise(async (resolve, reject)=>{

        if(!options.target) {
            console.log('No target for uploading. Pass a CRDTItem');
            reject();
            return;
        } else {
            options.target = options.target.CRDTItem || options.target
        }

        if(!options.field ) {
            console.log('No field or property name given. Pass a string of the field or property to write the image to.')
            reject();
            return;
        }


        const targetMediaArray = options.target.get(options.field);

        // If the images for the page aren't in a CRDT, create a new Y Array to store them
        if(targetMediaArray instanceof Y.Array === false ){
            const sharedMediaType = new Y.Array();
            sharedMediaType.insert(0, targetMediaArray || []);
            options.target.set(options.field, sharedMediaType);
        }

        //TODO: get id and metadata from amazon s3presigneduploadurl

        const uploadId = _.uniqueId('upload-');


        if(!url || url.length == 0 || !url.startsWith('https://') ) {

            const showRemoteAlert = new CustomEvent('open-remote-alert', {
               detail: {
                   message: 'Could not Add Invalid URL to Images: '+ url,
               }
            });

            document.dispatchEvent( showRemoteAlert );
            reject(showRemoteAlert.detail);
            return;
        }

        const urlInfo = helpers.getFiletypeAndOembedFromURL(url, {width: 1920});

        if( urlInfo.fileType !== 'vimeo' && urlInfo.fileType !== 'youtube' ){
            reject('Could not Add Invalid URL to Images: '+ url+'\nOnly Youtube and Vimeo URLs allowed.');
            return
        }



        let width = undefined;
        let height = undefined;
        let name = 'url-'+uploadId;
        let url_thumb = undefined;

        // if we have oembed info, use it to generate a poster img and put width/height props in model info
        if( urlInfo.oembedURL){

            const response = await fetch(urlInfo.oembedURL, {mode: 'cors'});
            let oembedInfo;

            try {
                oembedInfo = await response.json() || {};
            } catch(e) {
                // unable to load oembed info. Bail
                console.error(e);
                return;
            }


            if( oembedInfo.width && oembedInfo.height ){
                width = oembedInfo.width;
                height = oembedInfo.height;                
            } else {
                width = 1600;
                height = 1000;
            }

            if( urlInfo.fileType == 'vimeo' ){
                const thumbOembedInfo = helpers.getFiletypeAndOembedFromURL(url, {width: 250});
                const thumbResponse = await fetch(thumbOembedInfo.oembedURL, {mode: 'cors'});
                let thumbInfo;

                try {
                    thumbInfo = await thumbResponse.json() || {};
                } catch(e) {
                    // unable to load thumb. Bail
                    console.error(e);
                    return;
                }

                if( thumbInfo.thumbnail_url){
                    url_thumb = thumbInfo.thumbnail_url;
                }                

            } else if( oembedInfo.thumbnail_url){
                url_thumb = oembedInfo.thumbnail_url;
            }

            if( oembedInfo.title){
                name = oembedInfo.title;
            }

        }


        // get state
        const state = store.getState();
        // generate a hash for every file in the array
        const siteId = state.site.id;
        const userId = state.auth?.data?.id ?? 0;
        HashId.disableCompression();
        await HashId.enablePrefix();
        const hashIdGenerator = HashId.generator(siteId, userId);

        const hash = await hashIdGenerator.next().value;

        let data = {
            hash: hash,
            url: url,
            url_thumb: url_thumb,
            url_id: urlInfo.id,
            url_type: urlInfo.fileType,
            width: width,
            height: height,
            name: name,
            mime_type: "text/uri-list",
        } 

        await getS3MediaURL(hash, {
            data: data,
        }).then(model=>{

            let mediaModel;

            ydoc.transact(() => {

                mediaModel = applyChangesToYType(new Y.Map(), model.data, YTransactionTypes.NotUndoable);

                targetMediaArray.push([mediaModel]);

            }, YTransactionTypes.NotUndoable);

            if( !mediaModel ){
                return;
            }

            resolve( {
                url,
                model: mediaModel.toJSON()
            })
            return;

        }).catch(error=>{
            console.log('failed!!!!');
        });

    })

};

export const uploadMedia = (options, files) => {

    if(!options.target) {
        console.log('No target for uploading. Pass a CRDTItem')
        return;
    } else {
        options.target = options.target.CRDTItem || options.target
    }

    if(!options.field && !options.property) {
        console.log('No field or property name given. Pass a string of the field or property to write the image to.')
        return;
    }


    // field can optionally be an object inside the target model, as in: { poster: {} }
    if( options.field ) {
        const targetMediaArray = options.target.get(options.field);

        // If the images for the page aren't in a CRDT, create a new Y Array to store them
        if(targetMediaArray instanceof Y.Array === false ){
            const sharedMediaType = new Y.Array();
            sharedMediaType.insert(0, targetMediaArray || []);
            options.target.set(options.field, sharedMediaType);
        }
    }


    // get state
    const state = store.getState();

    // convert FileList to Array 
    const fileArray = Array.from(files);

    const getHashArray = new Promise(async (resolve) => {

        // generate a hash for every file in the array
        const siteId = state.site.id;
        const userId = state.auth?.data?.id ?? 0;
        HashId.disableCompression();
        await HashId.enablePrefix();
        const hashIdGenerator = HashId.generator(siteId, userId);

        resolve(fileArray.map(() => hashIdGenerator.next().value))

    });

    const uploadId = _.uniqueId('upload-');

    onUploadStart(uploadId);

    const previousPresignedUrlPromises = [];
    
    // async upload all files
    const result = fileArray.map(async (file, fileIndex) => {

        if(file.size === 0) {

            const showRemoteAlert = new CustomEvent('open-remote-alert', {
               detail: {
                   message: 'Could not upload file. If your files are hosted on a cloud storage service, first ensure they are downloaded to your local machine.',
               }
            });

            document.dispatchEvent( showRemoteAlert );
            return;
        }

        const hashArray = await getHashArray;

        // grab the pre-generated hash
        const hash = hashArray[fileIndex];

        // append to map so we can figure out in which order to insert
        // the newly uploaded files later
        if (options.field){

            const targetType = options.target.get(options.field);

            if(!sortedHashesByUploadTarget.has(targetType)) {
                sortedHashesByUploadTarget.set(targetType, []);
            }

            sortedHashesByUploadTarget.get(targetType).push(hash);

        }

        if(file.onHash) {
            file.onHash(hash);
        }

        // add dashes and remove unsafe chars
        const safeFileName = file.name
            .replace(/[#&?+,;"\'\\\^\ ]/g, "-")
            .replace(/([^a-zA-Z0-9-_\.]+)/g, "");

        // convert file to generic blob so we can change the name
        // and any backend process the file gets uploaded to will correctly read it
        const renamedFile = new Blob([file], { type: file.type });
        renamedFile.name = safeFileName;

        const s3SignRequestData = {};

        // only create one video element throughout this process
        // this will be used to create the image blob for the poster later
        let videoEl;

        if( file.type.startsWith('image') ){

            const imgEl = await convertFileToImage(renamedFile);

            if(imgEl) {

                if(file.onElementCreation) {
                    file.onElementCreation(imgEl);
                }

                // width and height only for valid image uploads
                s3SignRequestData.width = imgEl.naturalWidth;
                s3SignRequestData.height = imgEl.naturalHeight;
            }

        } else if( file.type.startsWith('video') ){

            videoEl = await convertFileToVideo(renamedFile);

            if(videoEl) {

                if(file.onElementCreation) {
                    file.onElementCreation(videoEl);
                }

                // width and height only for valid image uploads
                s3SignRequestData.width = videoEl.videoWidth;
                s3SignRequestData.height = videoEl.videoHeight;
            } else {

                // the presigned URL Promise will return a failure message
                // if uploading an obviously incorrect file format like .avi
                // however, in more subtle cases- like a plain mpeg-4 file in Chrome or
                // h265 on a platform that doesn't support it, the video creation will 
                // fail. We show this error here:
                const showRemoteAlert = new CustomEvent('open-remote-alert', {
                   detail: {
                       message: 'This type of file is not supported',
                   }
                });

                document.dispatchEvent( showRemoteAlert );
                return
            }

        }

        const presignedURLPromise = new Promise((resolve, reject) => {

            // first wait till all other presigned URL requests came through. This ensures
            // the server creates the models in the order they were dropped.
            Promise.all([...previousPresignedUrlPromises]).finally(() => {

                // all previous models have been created. Now it's this file's turn
                getS3PreSignedUploadUrl(renamedFile, hash, {
                    data: s3SignRequestData
                })
                .then((results)=>{

                    // currently the s3presigneduploadurl method doesn't limit media, so we do it here on the frontend
                    if( file.type.startsWith('video') && file.size /1048576 > 25 && options.field === 'media' ){
                        throw({
                            response: {
                                data: {
                                    message: 'The file “'+file.name+'” did not upload because it is larger than 25MB',
                                },
                            }
                        });
                        return;
                    }

                    resolve(results)
                }).catch((err) => {

                    let message = err?.response?.data?.message ? err?.response?.data?.message : 'Something went wrong.';

                    const showRemoteAlert = new CustomEvent('open-remote-alert', {
                       detail: {
                           message: message,
                       }
                    });

                    document.dispatchEvent( showRemoteAlert );

                    console.log("image upload error", err)

                    reject(message)
                    
                });

            });

        })

        // add to the queue so upcoming uploads know to wait
        previousPresignedUrlPromises.push(presignedURLPromise);

        const presignedURLResult = await presignedURLPromise;

        if( !presignedURLResult ){ return }

        let mediaModel;

        ydoc.transact(() => {

            // if it's a video, then we make a new model for its poster as well
            if( presignedURLResult.data.media?.is_video && videoEl ){

                // fill in poster with empty map immediately so we know it's on the way
                mediaModel = applyChangesToYType(new Y.Map(), {
                    ...presignedURLResult.data.media,
                    duration: Math.floor(videoEl.duration),
                    has_audio_track: videoEl.mozHasAudio || Boolean(videoEl.webkitAudioDecodedByteCount) || Boolean(videoEl.audioTracks && videoEl.audioTracks.length),
                    loading: true,
                    poster: new Y.Map()
                }, YTransactionTypes.NotUndoable);


                // try to play a frame before creating an image from it
                // but play this callback regardless
                const onPlay = ()=>{

                    requestAnimationFrame(()=>{
                        videoEl.pause();
                        const canvas = document.createElement('canvas');
                        canvas.width = videoEl.videoWidth;
                        canvas.height = videoEl.videoHeight;
                        canvas.getContext('2d').drawImage(videoEl, 0 , 0);                    
                        canvas.setAttribute('style', 'position: fixed; top: 0; left: 0; z-index: 999999999;');

                        canvas.toBlob((blob) => {

                            if(file.onBlobCreation) {
                                file.onBlobCreation(blob);
                            }                                

                            const imgFile = new File([blob], (file.name+'').split('.')[0]+'.jpg' , { type: 'image/jpg'});
                            uploadMedia({
                                target: mediaModel,
                                property: 'poster'
                            }, [imgFile])

                        }, 'image/jpg', 1.0);  
                    });                         
                };
     
                videoEl.muted = true;
                videoEl.setAttribute('muted', '');
                videoEl.setAttribute('playsinline', '');
                videoEl.play().then(onPlay).catch(onPlay)

            } else {

                mediaModel = applyChangesToYType(new Y.Map(), {
                    ...presignedURLResult.data.media,
                    loading: true
                }, YTransactionTypes.NotUndoable);

            }

            // This function is async so it's likely we're not arriving here in the same order we started execution. 
            // Make sure we insert models in the right order by inserting it before it's closest sibling if one has
            // already been inserted.

            // get list of ordered list of potential next siblings
            if (options.field){

                // the array we're going to insert in
                const targetMediaArray = options.target.get(options.field);

                // grab the list of hashes being uploaded into the target type
                const sortedHashes = sortedHashesByUploadTarget.get(targetMediaArray);

                // find all next siblings
                const nextSiblingHashes = sortedHashes.slice(sortedHashes.indexOf(hash));

                // we don't need to iterate over the entire media array every time, only the last bit.
                const itemsToCheck = Math.min(targetMediaArray.length, sortedHashes.length);

                // loop over the tail end of the media array, looking for siblings for the media item
                // we want to insert
                for(let i = targetMediaArray.length - itemsToCheck; i < targetMediaArray.length; i++) {

                    const item = targetMediaArray.get(i);

                    // grab hash of existing item
                    const existingHash = item instanceof Y.Map ? item.get('hash') : item.hash;

                    // found the first existing hash that is a sibling of the item we want to insert. 
                    // Insert the new item here so we maintain insertion order
                    if(existingHash && nextSiblingHashes.includes(existingHash) ) {

                        // insert before this nearest next sibling
                        targetMediaArray.insert(i, [mediaModel]);

                        // stop looping;
                        break;
                    }

                }

                if(!mediaModel.parent && !options.posterForModelID) {
                    // no next sibling found, insert at the end
                    targetMediaArray.push([mediaModel]);
                }

            } else if ( options.target.has(options.property) ){
                options.target.set(options.property, mediaModel)
            }

        }, YTransactionTypes.NotUndoable);

        if( !mediaModel ){
            return;
        }

        modelsBeingUploaded.push(mediaModel);

        // upload to S3
        await axios.put(presignedURLResult.data.url, renamedFile, {
            headers: {
                "Content-Type": renamedFile.type,
            },
            onUploadProgress: event => {
                wsProvider.setAwarenessField(`${mediaModel.get('hash')}-progress`, Math.floor((event.loaded / event.total) * 100));
            }
        }).catch((err) => {

            let message = err?.response?.data?.message ? err?.response?.data?.message : 'Something went wrong.';

            const showRemoteAlert = new CustomEvent('open-remote-alert', {
               detail: {
                   message: message,
               }
            });

            document.dispatchEvent( showRemoteAlert );

            console.log("image upload error", err)
            // make sure this transaction is not undoable
            ydoc.transact(() => {

                // delete the media model
                if(
                    mediaModel 
                    && mediaModel.parent 
                    && mediaModel.parent instanceof Y.Array
                ) {

                    const arr = mediaModel.parent.toArray();

                    for (let i = arr.length - 1; i >= 0; i--) {
                        if(arr[i] === mediaModel) {
                            mediaModel.parent.delete(i, 1);
                        }
                    }

                }

            }, YTransactionTypes.NotUndoable);

        }).finally(() => {

            // remove the model from the list of uploads in progress
            modelsBeingUploaded = modelsBeingUploaded.filter(model => model !== mediaModel);

        });

        ydoc.transact(() => {

            mediaModel.delete('loading');

            // get current local state
            const state = wsProvider.awareness.getLocalState();

            if(state && typeof state === "object") {
                // delete the upload state so we don't leave anything behind
                delete state[`${mediaModel.get('hash')}-progress`];
            }

            // set the new state
            wsProvider.awareness.setLocalState(state);

        }, YTransactionTypes.NotUndoable);

        return {
            file,
            model: mediaModel.toJSON()
        }

    });

    Promise.all(result).finally(() => {
        onUploadComplete(uploadId);
    })

    return result;

};
