import axios from "axios";
import { YTransactionTypes } from "../../globals";
import { getCRDTItem } from "../multi-user/redux";
import { store } from "../../index"
import { API_ORIGIN } from "@cargo/common";

export const getS3MediaURL = (hash, options = {}) => {

    const state = store.getState();

    return axios.post(
        `${API_ORIGIN}/media/mediaurl/${state.site.id}`,
        {
            hash: hash,
            mime_type: 'text/uri-list',
            ...(options.data || {})
        }, 
        {
            use_auth: true
        }
    );
}
