import axios from "axios";
import { YTransactionTypes } from "../../globals";
import { getCRDTItem } from "../multi-user/redux";
import { store } from "../../index"
import { API_ORIGIN } from "@cargo/common";

export const getS3PreSignedUploadUrl = (file, hash, options = {}) => {

    const state = store.getState();

    return axios.post(
        `${API_ORIGIN}/media/presignedurl/${state.site.id}${options.favicon ? '/favicon' : ''}${options.support ? '/support' : ''}`,
        {
            hash: hash,
            file_size: file.size,
            filename: file.name,
            mime_type: file.type,
            ...(options.data || {})
        }, 
        {
            use_auth: true
        }
    );
}
